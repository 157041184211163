import React from "react"
import Layout from "./../components/layout"
import PageHeader from "../components/pageHeader"
import casa from "../img/Casa.jpg"
import image2 from "../img/Casa1.jpg"
import image24 from "../img/Chiva.jpg"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"

const AboutPage = () => <Layout>
  <PageHeader backgroundImage={casa} title="About Us"/>
  <section className="section section_discover">
    <Container>
      <Row>
        <Col md={2} className="align-self-start">
          <p className="section_discover__description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>

        </Col>
        <Col md={4} className="align-self-start">
          <div className="section_discover__img">
            <img src={image2} className="img-fluid" alt="..."/>
          </div>
        </Col>
        <Col md={6} className="align-self-center">
          <h2><em>Lorem ipsum</em> dolor sit amet</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias veritatis nisi, consequatur, laborum
            libero a neque ducimus. Porro rem illum quo nostrum quisquam asperiores, blanditiis, consectetur. Possimus
            facilis velit, voluptatibus!
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis deleniti non, voluptate architecto, harum
            suscipit dignissimos eaque voluptatibus nobis natus error iure exercitationem. Officia facilis, maiores
            atque delectus commodi rerum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur qui, itaque, accusantium accusamus eum
            voluptate ducimus eligendi laboriosam alias mollitia omnis harum et ut quaerat, minus repellendus sint.
            Inventore, iure.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="section section_info section_info_opposite">
    <Container>
      <Row>
        <Col>
          <h2 className="section__heading text-center">
            Behind the scenes
          </h2>
          <p className="section__subheading text-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia obcaecati sit odio velit culpa aspernatur
            consectetur natus quidem minima veritatis.
          </p>
        </Col>
      </Row>
      <Row className="align-items-justify">
        <Col md={6} className="order-md-3">
          <div className="section_info__img">
            <img src={image24} alt="..."/>
          </div>
        </Col>
        <Col md={1} className="order-md-2"/>
        <Col md={5} className="order-md-1">
          <div className="section_info__body">
            <p className="lead text-heading">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident sequi ratione, accusamus quo mollitia
              architecto, explicabo obcaecati.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum enim saepe aperiam eius, quam blanditiis
              necessitatibus dolorem dignissimos pariatur quas velit nihil tempora, libero unde molestiae! Illum,
              inventore. Iusto, autem!
            </p>
            <br/>
            <p>
              <Link to="/" className="btn btn-primary">
                Contact Us
              </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
</Layout>

export default AboutPage;
